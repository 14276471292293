import { useEffect, FormEventHandler, useMemo } from "react"
import Checkbox from "@/components/Checkbox"
import GuestLayout from "@/layouts/GuestLayout"
import InputError from "@/components/InputError"
import InputLabel from "@/components/InputLabel"
import PrimaryButton from "@/components/Button"
import TextInput from "@/components/TextRefInput"
import { Head, Link, router, useForm } from "@inertiajs/react"
import { useMutation } from "@tanstack/react-query"
import { postConnect } from "@/services/ConnectionService"

type LoginPageProps = {
    status?: string
    canResetPassword: boolean
}

export default function Login({ status, canResetPassword }: LoginPageProps) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: "",
        password: "",
        remember: false,
    })

    const connection_identifier = useMemo(() => {
        return localStorage.getItem("connection_identifier")
    }, [])

    const { mutate } = useMutation({
        mutationKey: ["postLoginConnect"],
        mutationFn: async () => {
            if (connection_identifier !== null) {
                await postConnect({ identifier: connection_identifier })
            }
        },
    })

    useEffect(() => {
        return () => {
            reset("password")
        }
    }, [])

    const submit: FormEventHandler = (e) => {
        e.preventDefault()
        post(route("login"), {
            onSuccess: () => {
                mutate()
                router.get(route("dashboard"))
            },
        })
    }

    return (
        <GuestLayout>
            <Head title="Log in" />

            {status && (
                <div
                    className={`
                        mb-4 text-sm font-medium text-green-600

                        dark:text-green-400
                    `}
                >
                    {status}
                </div>
            )}

            <form onSubmit={submit}>
                <div>
                    <InputLabel htmlFor="email" value="Email" />

                    <TextInput
                        id="email"
                        type="email"
                        name="email"
                        value={data.email}
                        className="mt-1 block w-full"
                        autoComplete="username"
                        isFocused={true}
                        onChange={(e) => setData("email", e.target.value)}
                    />

                    <InputError message={errors.email} className="mt-2" />
                </div>

                <div className="mt-4">
                    <InputLabel htmlFor="password" value="Password" />

                    <TextInput
                        id="password"
                        type="password"
                        name="password"
                        value={data.password}
                        className="mt-1 block w-full"
                        autoComplete="current-password"
                        onChange={(e) => setData("password", e.target.value)}
                    />

                    <InputError message={errors.password} className="mt-2" />
                </div>

                <div className="mt-4 block">
                    <label className="flex items-center">
                        <Checkbox
                            name="remember"
                            checked={data.remember}
                            onChange={(e) =>
                                setData("remember", e.target.checked)
                            }
                        />
                        <span
                            className={`
                                ms-2 text-sm text-gray-600

                                dark:text-gray-400
                            `}
                        >
                            Remember me
                        </span>
                    </label>
                </div>

                <div className="mt-4 flex items-center justify-end">
                    {canResetPassword && (
                        <Link
                            href={route("password.request")}
                            className={`
                                rounded-md text-sm text-gray-600 underline

                                dark:text-gray-400 dark:hover:text-gray-100

                                focus:outline-none focus:ring-2
                                focus:ring-indigo-500 focus:ring-offset-2

                                hover:text-gray-900
                            `}
                        >
                            Forgot your password?
                        </Link>
                    )}

                    <PrimaryButton className="ms-4" disabled={processing}>
                        Log in
                    </PrimaryButton>
                </div>
            </form>
        </GuestLayout>
    )
}
